import React from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { app } from "../App";
import common_img from "../images/rarity/common_img.png";
import epic_img from "../images/rarity/epic_img.png";
import legendary_img from "../images/rarity/legendary_img.png";
import mythical_img from "../images/rarity/mythical_img.png";
import rare_img from "../images/rarity/rare_img.png";
import unique_img from "../images/rarity/unique_img.png";
import mainTabs from "./MainTabs.module.scss";
import rarityTabStyles from "./RarityTabsView.module.scss";

const rarities = [
	{
		name: "Common",
		image: common_img,
		amount: 57,
		description: "Common cards are the ones with the lowest level of card rarity. Common cards can be recognized by a grey frame. They form the basis of the game and enable simple, utile moves that form the basis of each game round.",
		mining_power: app.chainConf.MANA_MINING_POWER.symbols.common,
	},
	{
		name: "Rare",
		image: rare_img,
		amount: 52,
		description: "Rare is the second level of rarity above Common. Rare cards can be recognized by a frame colored in brown. With the rare cards in his deck, the player will be able to develop a more sophisticated strategy of the battle against his foe.",
		mining_power: app.chainConf.MANA_MINING_POWER.symbols.rare,
	},
	{
		name: "Unique",
		image: unique_img,
		amount: 28,
		description: "Unique is one of the middle levels of rarity, above Common, and Rare, but below Legendary and Mythical. Unique cards can be identified by a blue frame around the card's art. Unique cards are obtained from card packs much less frequently than Common or Rare and give the player more options of moves during the game round.",
		mining_power: app.chainConf.MANA_MINING_POWER.symbols.unique,
	},
	{
		name: "Epic",
		image: epic_img,
		amount: 21,
		description: "Epic is one of the middle levels of rarity, above Common, Rare, and Unique. Epic cards can be recognized by a purple frame. Epic cards become your tools for the epic moves.",
		mining_power: app.chainConf.MANA_MINING_POWER.symbols.epic,
	},
	{
		name: "Legendary",
		image: legendary_img,
		amount: 16,
		description: "Legendary is the second-highest level of rarity below Mythical. Legendary cards can be recognized by a yellow frame. Legendary cards are scarce to find in the card packs. Those cards provide their owner with the power of abrupt strategies and sudden maneuvers.",
		mining_power: app.chainConf.MANA_MINING_POWER.symbols.legendary,
	},
	{
		name: "Mythic",
		image: mythical_img,
		amount: 13,
		description: "Mythic cards - are the ones with the highest level of rarity. You can indicate them for a red&black frame. Mythical cards are obtained from card packs much less frequently than any other rarity.  Yet they are truly worth to hunt for since they arm their owner with the most powerful, cunning, and unexpected moves.",
		mining_power: app.chainConf.MANA_MINING_POWER.symbols.mythic,
	},
];

const RarityTabsView = () => {
	const tabsView = rarities.map((rarity, index) =>
		<Tab className={mainTabs.tabs_style}
		     key={`tab-${index}`}
		>
			<div className={mainTabs.lib_tab}>{rarity.name}</div>
		</Tab>,
	);

	const tabPanelsView = rarities.map((rarity, index) =>
		<TabPanel className={mainTabs.lib_tab_page}
		          key={`tab-panel-${index}`}
		>
			<div className={rarityTabStyles.lib_tab_page_desc}>
				<p>
					{rarity.description}
				</p>
				<hr/>
				<div className={rarityTabStyles.total}>The total amount: {rarity.amount}</div>
				<div className={rarityTabStyles.total}>Mining power: {rarity.mining_power}</div>
			</div>
			<img className={rarityTabStyles.images} src={rarity.image} alt={rarity.name}/>
		</TabPanel>,
	);

	return <Tabs className={mainTabs.tabs_container}
	             selectedTabClassName={mainTabs.active}
	             selectedTabPanelClassName={rarityTabStyles.selected_tab_page}
	             forceRenderTabPanel={true}>
		<TabList className={mainTabs.lib_tab_header}>
			{tabsView}
		</TabList>

		{tabPanelsView}
	</Tabs>;
};

export default RarityTabsView;
