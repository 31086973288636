import React from "react";
// import smallMedium from "../images/social/small_medium.png";
// import smallTelegram from "../images/social/small_telegram.png";
// import smallTwiter from "../images/social/small_twiter.png";
import wecan from "../images/tb_wecan_img.png";
import wecanLogo from "../images/wecan_logo.png";
import footer from "./footer.module.scss";
// import social from "./social.module.scss";

const Footer = () => {

	return (
		<div className={footer.content}>
			<div>
				{/*<div className={social.social_box}>*/}
				{/*	<a href="https://twitter.com/tribalbooks" target="_blank">*/}
				{/*		<img src={smallTwiter} alt="twiter"/>*/}
				{/*	</a>*/}
				{/*	<a href="https://t.me/tribalbooks" target="_blank">*/}
				{/*		<img src={smallTelegram} alt="telegram"/>*/}
				{/*	</a>*/}
				{/*	<a href="https://tribalbooks.medium.com/" target="_blank">*/}
				{/*		<img src={smallMedium} alt="medium"/>*/}
				{/*	</a>*/}
				{/*</div>*/}
				<a className={footer.email} href="mailto:support@tribalbooks.io">
					support@tribalbooks.io
				</a>
				<a className={footer.first_book} href={"/tribalbooks-the_first_books.pdf"} target="_blank">
					THE FIRST BOOKS
				</a>
				<br/>
				<div>
					© 2020 wecan.dev . All rights reserved.
				</div>
			</div>
			<div className={footer.right_content}>
				<a href="https://wecan.dev/" target="_blank">
					<img src={wecan} alt="logo wecan.dev"/>
					<img src={wecanLogo} alt="logo wecan.dev"/>
				</a>
			</div>
		</div>);
};

export default Footer;
