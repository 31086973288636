import React from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { app } from "../App";
import books_img from "../images/books.png";
import hero_img from "../images/mana_enchanting/heros_img.png";
import books from "./books.module.scss";
import Styles from "./Cards.module.scss";
import MiningRate from "./MiningRate";
import RarityTabsView from "./RarityTabsView";
import HeaderDecor from "./shared/HeaderDecor";
import { L } from "../l10n/L10n";

const Cards = () => {

	let heroesRarity = [
		{rarity: L.rarity.common, value: app.chainConf.MANA_MINING_POWER.heroes.common},
		{rarity: L.rarity.rare, value: app.chainConf.MANA_MINING_POWER.heroes.rare},
		{rarity: L.rarity.unique, value: app.chainConf.MANA_MINING_POWER.heroes.unique},
		{rarity: L.rarity.epic, value: app.chainConf.MANA_MINING_POWER.heroes.epic},
		{rarity: L.rarity.legendary, value: app.chainConf.MANA_MINING_POWER.heroes.legendary},
		{rarity: L.rarity.mythic, value: app.chainConf.MANA_MINING_POWER.heroes.mythic},
	];

	let booksRarity = [
		{rarity: L.rarity.common, value: app.chainConf.MANA_MINING_POWER.books.common},
		{rarity: L.rarity.rare, value: app.chainConf.MANA_MINING_POWER.books.rare},
		{rarity: L.rarity.unique, value: app.chainConf.MANA_MINING_POWER.books.unique},
		{rarity: L.rarity.epic, value: app.chainConf.MANA_MINING_POWER.books.epic},
		{rarity: L.rarity.legendary, value: app.chainConf.MANA_MINING_POWER.books.legendary},
		{rarity: L.rarity.mythic, value: app.chainConf.MANA_MINING_POWER.books.mythic},
	];

	return (
		<Tabs
			className={Styles.tabsContainer}
			selectedTabClassName={Styles.active}
			selectedTabPanelClassName={Styles.selectedTabPage}
			forceRenderTabPanel={true}
		>
			<TabList className={Styles.libTabHeader}>
				<HeaderDecor/>
				<Tab className={Styles.tabsStyle}>
					<div className={Styles.libTab}>{L.cards.title.heroes}</div>
				</Tab>
				<HeaderDecor/>
				<Tab className={Styles.tabsStyle}>
					<div className={Styles.libTab}>{L.cards.title.symbols}</div>
				</Tab>
				<HeaderDecor/>
				<Tab className={Styles.tabsStyle}>
					<div className={Styles.libTab}>{L.cards.title.books}</div>
				</Tab>
				<HeaderDecor/>
			</TabList>

			<TabPanel className={Styles.libTabPage}>
				<div>
					<div className={books.content}>
						<p>{L.cards.description.heroes.p_1}</p>
						<div className={books.desc}>
							<MiningRate name={"Mining rate:"} value={heroesRarity}/>
							<img className={books.image} src={hero_img} alt="books images "/>
						</div>
						<p>{L.cards.description.heroes.p_2}</p>
					</div>
				</div>
			</TabPanel>

			<TabPanel className={Styles.libTabPage}>
				<div>
					<p>{L.cards.description.symbols.p_1}</p>
					<RarityTabsView/>
				</div>
			</TabPanel>

			<TabPanel className={Styles.libTabPage}>
				<div>
					<div className={books.content}>
						<p>{L.cards.description.books.p_1}</p>
						<div className={books.desc}>
							<MiningRate name={"Mining rate:"} value={booksRarity}/>
							<img className={books.image} src={books_img} alt="books images "/>
						</div>
						<p>{L.cards.description.books.p_2}</p>
					</div>
				</div>
			</TabPanel>
		</Tabs>
	);
};

export default Cards;
