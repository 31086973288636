import React from "react";
import metaFrame from "./mFrame.module.scss";

const DecoratedTitleView = (
	{
		text,
		id,
	}: {
		text: string
		id?: string,
	},
) => (
	<div className={metaFrame.title_box}>
		<h2 id={id}>{text}</h2>
	</div>
);

export default DecoratedTitleView;
