import React, { ReactNode, useCallback, useState } from "react";
import { elastic as Menu, State as MenuState } from "react-burger-menu";
import "./BurgerMenuLanding.scss";

export type BuildBurgerFnType = () => void

export type BurgerMenuProps = {
	buildBurger?: (closeMenu: BuildBurgerFnType) => ReactNode
}

const DefaultBurgerBuilder = () => <>burger builder error</>

export const BurgerMenu = ({ buildBurger = DefaultBurgerBuilder }: BurgerMenuProps) => {
	const [menuOpen, setMenuOpen] = useState(false)

	const closeMenu = useCallback(() => setMenuOpen(false), [])

	return (
		<Menu
			right
			isOpen={menuOpen}
			onStateChange={(state: MenuState) => setMenuOpen(state.isOpen)}
		>
			{buildBurger(closeMenu)}
		</Menu>
	)
}
