import scrollTo from "gatsby-plugin-smoothscroll";
import React from "react";
import Styles from "./shared/HeaderLink.module.scss";

type HeaderScrollBtnProps = {
	href: string;
	onClick?: () => void
	children: React.ReactNode;
}

export class HeaderScrollBtn extends React.Component<HeaderScrollBtnProps>
{
	handleClick()
	{
		// window.location.hash = this.props.href; // It scroll to anchor immediately
		scrollTo(this.props.href);
		this.props.onClick?.();
	}

	render()
	{
		return (
			<div className={Styles.headerLink}
			     onClick={() => this.handleClick()}>
				{this.props.children}
			</div>
		);
	}
}
