import React from "react";
import Styles from "./MiningRate.module.scss";

const MiningRate = (
	{
		name,
		value,
	}: {
		name: string,
		value: Array<{rarity: string, value: number}>
	}) => {

	return (
		<div className={Styles.content}>
			<h4>{name}</h4>
			<div className={Styles.column}>
				{value.map(item =>
					<div className={Styles.row} key={`mining-rate-${item.rarity}`}>
						<p>{item.rarity}:</p>
						<p>{item.value} mana</p>
					</div>
				)}
			</div>
		</div>
	);
};

export default MiningRate;
