import React from "react";
import InternalLink from "../game/InternalLink";
import Styles from "./GameLink.module.scss";

const GameLink = (
	{
		href,
		children,
	}: {
		href: string,
		children?: React.ReactNode
	},
) => (
	<InternalLink
		href={href}
		className={Styles.gameLink}
	>
		{children}
	</InternalLink>
);

export default GameLink;